<template>
  <div>
    <div class="top">
      <a-input
        placeholder="输入模板标题进行搜索"
        v-model="searchInpt"
        @pressEnter="search"
        @change="valueNull"
      />
      <button class="search" @click="search">搜索</button>
      <button class="add" @click="openModal()">新增</button>
    </div>
    <div class="h-100-h">
      <a-table
        :columns="list"
        :data-source="data"
        rowKey="id"
        @change="changePage"
        :pagination="pagination"
      >
        <a slot="name" slot-scope="text">{{ text }}</a>
        <div slot="setup" slot-scope="row">
          <a @click="openModal(row)" style="margin-right: 5px">编辑</a>
          <a-popconfirm
            title="确定要删除此项么？"
            ok-text="确认"
            cancel-text="取消"
            @confirm="remove(row.id)"
          >
            <a style="margin-left: 5px">删除</a>
          </a-popconfirm>
        </div>
      </a-table>
    </div>
    <a-modal
      v-model="visible"
      :title="innerTitle"
      ok-text="确认"
      :width="1000"
      cancel-text="取消"
      @cancel="resetForms"
      @ok="hideModal"
    >
      <div class="modal">
        <p>关键字</p>
        <a-input :disabled='id ? true : false' placeholder="" v-model="typeKey" />
        <p>标题</p>
        <a-input placeholder="" v-model="title" />
        <p>内容</p>
        <div style="height: 350px">
          <quill-editor
            ref="myTextEditor"
            v-model="text"
            :options="editorOption"
            style="height: 250px"
          ></quill-editor>
        </div>
      </div>
    </a-modal>
  </div>
</template>
<script>
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";

export default {
  components: {
    quillEditor,
  },
  data() {
    return {
      editorOption: {
        placeholder: "编辑文章内容",
      },
      innerTitle: "123",
      visible: false,
      typeKey: "",
      title: "",
      text: "",
      openType: "",
      type: "",
      list: [
        { title: "id", dataIndex: "id", align: "center" },
        {
          title: "标题",
          align: "center",
          dataIndex: "title",
        },
        {
          title: "关键字",
          dataIndex: "typeKey",
          align: "center",
        },

        {
          title: "路径",
          dataIndex: "path",
          align: "center",
        },

        {
          title: "操作",
          align: "center",
          scopedSlots: { customRender: "setup" },
        },
      ],
      data: [],
      pagination: {
        pageSize: 8,
        total: 0,
        current: 1,
        showTotal: (total) => `共${total}条`,
      },
      params: { page: 1, limit: 8, title: "" },
      id: "",
      searchInpt: "",
    };
  },
  methods: {
    getList(params) {
      this.axios("/dq_admin/textTemplate/list", {
        params: params,
      }).then((res) => {
        let list = res.data.records;
        for (let i = 0; i < list.length; i++) {
          if (list[i].type == 1) {
            list[i].type = "协议";
          }
          list[i].serialNumber = i + 1;
        }
        this.data = list;
        this.pagination.total = res.data.total;
      });
    },
    changePage(page) {
      const { current, pageSize } = page;

      this.pagination.current = current;
      this.params.page = current;
      this.params.limit = pageSize;
      this.getList(this.params);
    },
    search(e) {
      if (this.searchInpt == "") {
        this.$message.warning("请输入模板标题再进行搜索");
      } else {
        this.params.page = 1;
        this.params.title = this.searchInpt;
        this.getList(this.params);
        this.pagination.current = 1;
      }
    },
    valueNull() {
      if (this.searchInpt == "") {
        this.params.title = this.searchInpt;
        this.getList(this.params);
        this.pagination.current = 1;
      }
    },
    openModal(row) {
      if (row) {
        this.visible = true;
        this.innerTitle = "编辑模板";
        this.id = row.id;
        this.typeKey = row.typeKey;
        this.axios("/dq_admin/textTemplate/getById", {
          params: { id: row.id },
        }).then((res) => {
          this.text = res.data.text;

        });
        this.title = row.title;

        this.text = row.text;
        this.openType = 1;
      } else {
        this.openType = 2;
        this.typeKey = "";
        this.title = "";
        this.text = "";
        this.visible = true;
        this.innerTitle = "新增模板";
      }
    },
    remove(id) {
      this.axios("/dq_admin/textTemplate/delById", {
        params: { id: id },
      }).then((res) => {
        this.getList(this.params);

        this.$message.success("删除成功");
      });
    },
    hideModal() {
      if (this.typeKey == "") {
        this.$message.warning("请输入文本关键字");
      } else if (this.title == "") {
        this.$message.warning("请输入文本标题");
      } else if (this.text == "") {
        this.$message.warning("请输入文本内容");
      } else {
        if (this.openType == 1) {
          this.axios
            .post("/dq_admin/textTemplate/edit", {
              imgUrl: "",
              typeKey: this.typeKey,
              text: this.text,
              title: this.title,
              type: 1,
              id: this.id,
            })
            .then((res) => {
              this.getList(this.params);
              this.visible = false;
              this.$message.success("修改成功");
            });
        } else {
          this.axios
            .post("/dq_admin/textTemplate/add", {
              imgUrl: "",
              typeKey: this.typeKey,
              text: this.text,
              title: this.title,
              type: 1,
            })
            .then((res) => {
              this.getList(this.params);
              this.visible = false;
              this.$message.success("新增成功");
            });
        }
      }
    },
    resetForms() {},
  },
  mounted() {
    this.getList(this.params);
  },
};
</script>
<style lang="scss" scoped>
.top {
  input {
    width: 250px;
    margin-right: 25px;
    margin-bottom: 20px;
  }
  button {
    width: 65px;
    height: 28px;
    border: none;
    background: #1890ff;
    color: #ffffff;
    border-radius: 5px;
    margin-right: 25px;
    outline-style: none;
    cursor: pointer;
  }
}
.modal {
  input {
    margin-bottom: 5px;
  }
}
</style>