var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "top" },
        [
          _c("a-input", {
            attrs: { placeholder: "输入模板标题进行搜索" },
            on: { pressEnter: _vm.search, change: _vm.valueNull },
            model: {
              value: _vm.searchInpt,
              callback: function($$v) {
                _vm.searchInpt = $$v
              },
              expression: "searchInpt"
            }
          }),
          _c("button", { staticClass: "search", on: { click: _vm.search } }, [
            _vm._v("搜索")
          ]),
          _c(
            "button",
            {
              staticClass: "add",
              on: {
                click: function($event) {
                  return _vm.openModal()
                }
              }
            },
            [_vm._v("新增")]
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "h-100-h" },
        [
          _c("a-table", {
            attrs: {
              columns: _vm.list,
              "data-source": _vm.data,
              rowKey: "id",
              pagination: _vm.pagination
            },
            on: { change: _vm.changePage },
            scopedSlots: _vm._u([
              {
                key: "name",
                fn: function(text) {
                  return _c("a", {}, [_vm._v(_vm._s(text))])
                }
              },
              {
                key: "setup",
                fn: function(row) {
                  return _c(
                    "div",
                    {},
                    [
                      _c(
                        "a",
                        {
                          staticStyle: { "margin-right": "5px" },
                          on: {
                            click: function($event) {
                              return _vm.openModal(row)
                            }
                          }
                        },
                        [_vm._v("编辑")]
                      ),
                      _c(
                        "a-popconfirm",
                        {
                          attrs: {
                            title: "确定要删除此项么？",
                            "ok-text": "确认",
                            "cancel-text": "取消"
                          },
                          on: {
                            confirm: function($event) {
                              return _vm.remove(row.id)
                            }
                          }
                        },
                        [
                          _c("a", { staticStyle: { "margin-left": "5px" } }, [
                            _vm._v("删除")
                          ])
                        ]
                      )
                    ],
                    1
                  )
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: {
            title: _vm.innerTitle,
            "ok-text": "确认",
            width: 1000,
            "cancel-text": "取消"
          },
          on: { cancel: _vm.resetForms, ok: _vm.hideModal },
          model: {
            value: _vm.visible,
            callback: function($$v) {
              _vm.visible = $$v
            },
            expression: "visible"
          }
        },
        [
          _c(
            "div",
            { staticClass: "modal" },
            [
              _c("p", [_vm._v("关键字")]),
              _c("a-input", {
                attrs: { disabled: _vm.id ? true : false, placeholder: "" },
                model: {
                  value: _vm.typeKey,
                  callback: function($$v) {
                    _vm.typeKey = $$v
                  },
                  expression: "typeKey"
                }
              }),
              _c("p", [_vm._v("标题")]),
              _c("a-input", {
                attrs: { placeholder: "" },
                model: {
                  value: _vm.title,
                  callback: function($$v) {
                    _vm.title = $$v
                  },
                  expression: "title"
                }
              }),
              _c("p", [_vm._v("内容")]),
              _c(
                "div",
                { staticStyle: { height: "350px" } },
                [
                  _c("quill-editor", {
                    ref: "myTextEditor",
                    staticStyle: { height: "250px" },
                    attrs: { options: _vm.editorOption },
                    model: {
                      value: _vm.text,
                      callback: function($$v) {
                        _vm.text = $$v
                      },
                      expression: "text"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }